import React, { useRef, useEffect } from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';
import './Fleetsoftware.css';
import 'swiper/css';
import 'swiper/css/effect-coverflow';
import 'swiper/css/pagination';
import 'swiper/css/navigation';
import 'swiper/css/autoplay';
import { Swiper, SwiperSlide } from 'swiper/react';
import { EffectCoverflow, Autoplay, Pagination } from 'swiper/modules';
import Plot from "react-plotly.js";
import { motion } from 'framer-motion';


const Fleetsoftware = () => {


    useEffect(() => {
        window.scrollTo(0, 0); // Scroll to the top of the page
    }, []);

    const swiperRef = useRef(null); // To reference the Swiper instance
    useEffect(() => {
        // Ensure the swiper instance is ready
        const swiper = swiperRef.current.swiper;
        if (swiper) {

            const handleMouseEnter = () => {
                swiper.autoplay.stop(); // Stop autoplay on hover
            };

            const handleMouseLeave = () => {
                swiper.autoplay.start(); // Restart autoplay when hover ends
            };

            // Attach event listeners for each slide
            const slides = document.querySelectorAll('.swiper-slide');
            slides.forEach(slide => {
                slide.addEventListener('mouseenter', handleMouseEnter);
                slide.addEventListener('mouseleave', handleMouseLeave);
            });

            // Clean up the event listeners when the component is unmounted
            return () => {
                slides.forEach(slide => {
                    slide.removeEventListener('mouseenter', handleMouseEnter);
                    slide.removeEventListener('mouseleave', handleMouseLeave);
                });
            };
        }
    }, []);

    const xArray = [4, 8, 12, 16, 20];
    const yArray = ["AMR 5", "AMR 4", "AMR 3", "AMR 2", "AMR 1"];

    const splitText = (text) => {
        return text.split('').map((char, index) => (
            <motion.span
                key={index}
                initial={{ x: -20, opacity: 0 }}
                animate={{ x: 0, opacity: 1 }}
                transition={{ duration: 0.2, delay: index * 0.05 }}
            >
                {char}
            </motion.span>
        ));
    };



    return (

        <div data-bs-spy="scroll" data-bs-target=".navbar" data-bs-offset="50" >
            <section className='sec'>
                {/* <div className='content'>
                    {window.innerWidth > 867 && (
                        <div className='softwareDesign' >
                            <div class="grid-container">
                                <div class="item1">
                                    <div className='progressBar'>
                                        <div className='bar' style={{ width: '30%' }}></div>
                                    </div>
                                    <div className='progressBar'>
                                        <div className='bar' style={{ width: '90%' }}></div>
                                    </div>
                                    <div className='progressBar'>
                                        <div className='bar' style={{ width: '60%' }}></div>
                                    </div>
                                    <div className='progressBar'>
                                        <div className='bar' style={{ width: '80%' }}></div>
                                    </div>
                                    <div className='progressBar'>
                                        <div className='bar' style={{ width: '100%' }}></div>
                                    </div>
                                </div>
                                <div class="item2">
                                    <img src='/Images/bg/industryMap.png' alt='industryMap' loading='lazy' />
                                </div>
                                <div class="item4">
                                    <table className='fleetTable'>
                                        <thead className='fleetTableHead'>
                                            <th>Robot</th>
                                            <th>Source</th>
                                            <th>Destination</th>
                                            <th>Status</th>
                                        </thead>
                                        <tbody>
                                            <tr>
                                                <td>AMR 1</td>
                                                <td>Point A</td>
                                                <td>Point B</td>
                                                <td>Running</td>
                                            </tr>
                                            <tr>
                                                <td>AMR 2</td>
                                                <td>Point C</td>
                                                <td>Point D</td>
                                                <td>Completed</td>
                                            </tr>
                                            <tr>
                                                <td>AMR 3</td>
                                                <td>Point E</td>
                                                <td>Point F</td>
                                                <td>Waiting</td>
                                            </tr>
                                            <tr>
                                                <td>AMR 4</td>
                                                <td>Point G</td>
                                                <td>Point H</td>
                                                <td>Running</td>
                                            </tr>
                                            <tr>
                                                <td>AMR 5</td>
                                                <td>trytcuvy</td>
                                                <td>txyctuvy</td>
                                                <td>Completed</td>
                                            </tr>
                                        </tbody>
                                    </table>
                                </div>
                                <div className="item5" style={{ textAlign: "right" }}>
                                    <Plot
                                        data={[
                                            {
                                                x: xArray,
                                                y: yArray,
                                                type: "bar",
                                                orientation: "h",
                                                marker: { color: "#0083b3" },
                                            },
                                        ]}
                                        layout={{
                                            width: 450, // Adjust width for alignment
                                            height: 300, // Adjust height for alignment
                                            paper_bgcolor: "rgba(0,0,0,0)", // Transparent background for the paper (whole chart)
                                            plot_bgcolor: "rgba(0,0,0,0)", // Transparent background for the plotting area
                                            font: {
                                                color: "#fff", // Change font color to make it visible on the transparent background
                                            },
                                        }}
                                        config={{
                                            staticPlot: true, // Make the chart non-editable
                                        }}
                                    />
                                </div>
                            </div>
                        </div>





                    )}
                </div> */}
                <div className='headerOutContaier'>
                    <div className='contentTextFleet'>
                        <h1 >
                            {splitText('NXS Fleet Manager')}
                        </h1>

                        <p>
                            {splitText('Streamline autonomy with smart scheduling,real-time tracking, and safer operations')}
                        </p>
                    </div>
                </div>
            </section>






            <section className="keyfeaturesection " >
                <div class="keyFeatures">
                    <h1 className='h1Heading' ></h1>
                </div>

                <div className='collection'>
                    <Swiper
                        ref={swiperRef}
                        modules={[EffectCoverflow, Autoplay, Pagination]}
                        effect="coverflow"
                        grabCursor
                        centeredSlides
                        loop
                        slidesPerView="auto"
                        spaceBetween={window.innerWidth <= 768 ? 20 : 0}
                        coverflowEffect={{
                            rotate: 0,
                            stretch: 0,
                            depth: 150,
                            modifier: 2.5,
                            slideShadows: false,
                        }}
                        pagination={window.innerWidth <= 768 ? true : false}
                        autoplay={{
                            delay: 3000,
                            disableOnInteraction: false,
                        }}

                        className="mySwiper"

                    >

                        <SwiperSlide key={1} className="content"
                        >
                            <div className="circle-image-wrapper">
                                <img src="Images/svg/Asset 14.svg" alt="Content Icon" loading='lazy' className="circle-image" />
                            </div>
                            <div className="text-content">
                                <h3>Path Configuration</h3>
                                <p>Optimize robot routes with <b>flexible path creation tools</b>, enabling seamless navigation in dynamic environments.
                                    Easily define <b>restricted zones</b> for safety, preventing robots from entering hazardous areas or interfering with sensitive operations.
                                    These features ensure efficient task execution and enhanced safety across your workspace.</p>
                            </div>
                        </SwiperSlide>
                        <SwiperSlide key={2} className="content">
                            <div className="circle-image-wrapper">
                                <img src="Images/svg/Asset 8.svg" alt="Content Icon" loading='lazy' className="circle-image" />
                            </div>
                            <div className="text-content">
                                <h3>Fleet Management Dashboard</h3>
                                <p>A <b>centralized control hub </b>that provides comprehensive visibility and control of all robots in your fleet.
                                    Monitor their status, manage tasks, and analyze performance in one intuitive interface.
                                    The dashboard simplifies fleet coordination, making it ideal for complex multi-robot environments.</p>
                            </div>
                        </SwiperSlide>
                        <SwiperSlide key={3} className="content" >
                            <div className="circle-image-wrapper">
                                <img src="Images/svg/Asset 9.svg" alt="Content Icon" loading='lazy' className="circle-image" />
                            </div>
                            <div className="text-content">
                                <h3>IOT, Industry 4.0 ready</h3>
                                <p>Designed for the future of industrial operations, the system integrates seamlessly with <b>smart devices and IoT ecosystems</b>.
                                    Benefit from <b>real-time data exchange</b>, <b>advanced automation</b>, and <b>predictive analytics </b>to improve operational efficiency and scalability.
                                    It empowers businesses to stay connected and competitive in the era of Industry 4.0.</p>
                            </div>
                        </SwiperSlide>
                        <SwiperSlide key={4} className="content">
                            <div className="circle-image-wrapper">
                                <img src="Images/svg/Asset 10.svg" alt="Content Icon" loading='lazy' className="circle-image" />
                            </div>
                            <div className="text-content">
                                <h3>Mission Scheduling</h3>
                                <p>Simplify task planning with <b>advanced scheduling tools </b>that allow you to prioritize and allocate tasks efficiently. Schedule recurring missions, assign tasks to specific robots, and adapt to changing priorities with ease.
                                    This feature ensures that operations run smoothly without delays.</p>
                            </div>
                        </SwiperSlide>
                        <SwiperSlide key={5} className="content">
                            <div className="circle-image-wrapper">
                                <img src="Images/svg/Asset 12.svg" alt="Content Icon" loading='lazy' className="circle-image" />
                            </div>
                            <div className="text-content">
                                <h3>Real-time Robot Updates</h3>
                                <p>Stay informed with <b>live updates on robot activities</b>, including their current tasks, locations, and health metrics.
                                    This level of transparency improves <b>operational visibility</b>, enabling quicker decisions and proactive issue resolution.</p>
                            </div>
                        </SwiperSlide>
                        <SwiperSlide key={6} className="content">
                            <div className="circle-image-wrapper">
                                <img src="Images/svg/Asset 13.svg" alt="Content Icon" loading='lazy' className="circle-image" />
                            </div>
                            <div className="text-content">
                                <h3>Predictive Maintenance Alerts</h3>
                                <p>Avoid unexpected downtime with <b>intelligent maintenance alerts</b>.
                                    The system monitors robot performance in real time and provides early warnings for potential issues, allowing you to schedule maintenance proactively.
                                    This feature reduces costs and ensures the long-term reliability of your fleet.</p>
                            </div>
                        </SwiperSlide>

                    </Swiper>
                </div>
            </section>




            {/* <section className="features-section" style={{ height: window.innerWidth < 868 ? '180vh' : '110vh' }}>


                <h1 style={{ position: 'absolute', marginTop: window.innerWidth < 868 ? '-1100px' : '-30%', color: 'white' }} >
                    Features
                </h1>
                <p style={{ position: 'absolute', marginTop: window.innerWidth < 868 ? '-950px' : '-20%', color: 'white', textAlign: 'center' }}>
                    Our website serves as a comprehensive tool for controlling and monitoring
                    autonomous mobile robots within an industrial facility.
                </p>






                <div className="features" style={{ marginTop: window.innerWidth < 868 ? '75%' : '15%' }}>
                    <div className="feature feature-one" >
                        <h2 className="feature__title">TrackMate</h2>
                        <p className="feature__desc">A dynamic live map tracks robot positions in real-time, enabling seamless monitoring and planning.</p>
                        <img
                            className="feature__img"
                            src="https://kellychi22.github.io/frontend-mentor-solutions/10-four-card-feature-section/images/icon-supervisor.svg"
                            alt="Supervisor"
                        />
                    </div>
                    <div className="wrapper">
                        <div className="feature feature-two">
                            <h2 className="feature__title">PathMaster</h2>
                            <p className="feature__desc">Simplifying waypoint and path creation for seamless navigation.
                            </p>
                            <img
                                className="feature__img"
                                src="https://kellychi22.github.io/frontend-mentor-solutions/10-four-card-feature-section/images/icon-team-builder.svg"
                                alt="Team Builder"
                            />
                        </div>
                        <div className="feature feature-three">
                            <h2 className="feature__title">RoboPulse</h2>
                            <p className="feature__desc">Track robot health and performance in real-time.</p>
                            <img
                                className="feature__img"
                                src="https://kellychi22.github.io/frontend-mentor-solutions/10-four-card-feature-section/images/icon-karma.svg"
                                alt="Karma"
                            />
                        </div>
                    </div>
                    <div className="feature feature-four">
                        <h2 className="feature__title">TaskFlow</h2>
                        <p className="feature__desc">Automate and optimize workflows with seamless task sequences and smart path management.</p>
                        <img
                            className="feature__img"
                            src="https://kellychi22.github.io/frontend-mentor-solutions/10-four-card-feature-section/images/icon-calculator.svg"
                            alt="Calculator"
                        />
                    </div>
                </div>



            </section> */}
            {/* 
            <div className='headingStyleContainer' style={{ position: 'absolute', left: '50%', marginTop: '3%', transform: 'translate(-50%, -50%)', width: '500px', height: '50px', borderBottom: '5px solid #000', lineHeight: '43px', background: '#f1f1f4', overflow: 'hidden' }}>
                <span style={{ color: '#fff', fontSize: '30px', background: '#000', display: 'inline-block', padding: '2px 20px', textTransform: 'uppercase' }}>Advantages</span>
            </div> */}
            {/* <div class="advantages">
                <h1 className='h1Heading' style={{ textAlign: 'center', paddingTop: window.innerWidth < 868 ? '5%' : '0%', background: '#f1f1f4' }}></h1>
            </div> */}

            {/* <h1 style={{ textAlign: 'center',background: '#f1f1f4', marginBottom: '-3%' , paddingTop: '3%'}}>Advantages</h1> */}

            <section className='row featurecontainer'>
                {/* Left Half */}

                {/* { window.innerWidth > 1023  && ( */}
                <div className='featuretriangle'>
                    <div className='fleetadv'>
                        <div className="diamondgrid">
                            <div className="diamondlayout">
                                <div className="diamonditem">
                                    <div className="diamondcontent">

                                    </div>
                                </div>
                                <div className="diamonditem">
                                    <div className="diamondcontent">

                                    </div>
                                </div>
                            </div>
                            <div className="diamondlayout">
                                <div className="diamonditem">
                                    <div className="diamondcontent">

                                    </div>
                                </div>
                                <div className="diamonditem">
                                    <div className="diamondcontent">

                                    </div>
                                </div>
                            </div>
                            <div className="diamondlayout">
                                <div className="diamonditem">
                                    <div className="diamondcontent">

                                    </div>
                                </div>
                                <div className="diamonditem">
                                    <div className="diamondcontent">

                                    </div>
                                </div>
                            </div>
                            <div className="diamondlayout">
                                <div className="diamonditem">
                                    <div className="diamondcontent">

                                    </div>
                                </div>
                                <div className="diamonditem">
                                    <div className="diamondcontent">

                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                {/* )} */}

                {/* Right Half */}
                {/* <div  className='features-section'>

                    <div className="features" style={{ alignItems:'center'}}>
                        <div className="feature feature-one" >
                            <h2 className="feature__title">TrackMate</h2>
                            <p className="feature__desc">A dynamic live map tracks robot positions in real-time, enabling seamless monitoring and planning.</p>
                          
                            <img
                                className="feature__img"
                                src='/Images/icons/tracking.png'
                                alt='trackIcon'
                                loading='lazy'
                            />
                        </div>
                        <div>
                            <div className="feature feature-two">
                                <h2 className="feature__title">PathMaster</h2>
                                <p className="feature__desc">Simplifying waypoint and path creation for seamless navigation.
                                </p>
                          
                                <img
                                    className="feature__img"
                                    src='/Images/icons/road-map.png'
                                    alt='mapIcon'
                                    loading='lazy'
                                />

                            </div>
                            <div className="feature feature-three">
                                <h2 className="feature__title">RoboPulse</h2>
                                <p className="feature__desc">Track robot health and performance in real-time.</p>
                              
                                <img
                                    className="feature__img"
                                    src='/Images/icons/health-care.png'
                                    alt='healthcareIcon'
                                    loading='lazy'
                                />

                            </div>
                        </div>
                        <div className="feature feature-four">
                            <h2 className="feature__title">TaskFlow</h2>
                            <p className="feature__desc">Automate and optimize workflows with seamless task sequences and smart path management.</p>
                           
                            <img
                                className="feature__img"
                                src='/Images/icons/project-management.png'
                                alt='scheduleIcon'
                                loading='lazy'
                            />
                        </div>
                    </div>
                </div> */}

                <div className="features-section">
                    <div className="features" style={{ alignItems: 'center' }}>
                     
                        <div className="feature feature-one">
                            <h2 className="feature__title">TrackMate</h2>
                            <p className="feature__desc">A dynamic live map tracks robot positions in real-time, enabling seamless monitoring and planning.</p>
                            <img
                                className="feature__img"
                                src="/Images/icons/tracking.png"
                                alt="trackIcon"
                                loading="lazy"
                            />
                        </div>

                     
                        <div className="feature feature-two">
                            <h2 className="feature__title">PathMaster</h2>
                            <p className="feature__desc">Simplifying waypoint and path creation for seamless navigation.</p>
                            <img
                                className="feature__img"
                                src="/Images/icons/road-map.png"
                                alt="mapIcon"
                                loading="lazy"
                            />
                        </div>
                        <div className="feature feature-three">
                            <h2 className="feature__title">RoboPulse</h2>
                            <p className="feature__desc">Track robot health and performance in real-time.</p>
                            <img
                                className="feature__img"
                                src="/Images/icons/health-care.png"
                                alt="healthcareIcon"
                                loading="lazy"
                            />
                        </div>

                    
                        <div className="feature feature-four">
                            <h2 className="feature__title">TaskFlow</h2>
                            <p className="feature__desc">Automate and optimize workflows with seamless task sequences and smart path management.</p>
                            <img
                                className="feature__img"
                                src="/Images/icons/project-management.png"
                                alt="scheduleIcon"
                                loading="lazy"
                            />
                        </div>
                    </div>
                </div>

            </section>


        </div>
    );
};

export default Fleetsoftware;
