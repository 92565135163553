import React from 'react';
import './sample.css';

function sample() {
  return (
    <div className="App">
        <section style={{height: '100vh', background: '#f1f1f4'}}>

            <div style={{alignItems: 'center', justifyContent:'center', height: '100vh', display: 'flex'}}>
                <div style={{height: '15vh', width: '25vw', background: 'yellow', borderRadius: '20px'}}>
                    <div style={{height: '13.9vh', top: '0', width: '15vh', background: 'green', borderTopLeftRadius: '20px', borderBottomLeftRadius: '8px'}}>

                    </div>
                </div>

            </div>

        </section>
    </div>
  );
}

export default sample;
