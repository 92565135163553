import React from 'react';
import { useEffect } from 'react';
import { motion } from 'framer-motion';
import './Travo.css';


const Pro = () => {

    const splitText = (text) => {
        return text.split('').map((char, index) => (
            <motion.span
                key={index}
                initial={{ x: -20, opacity: 0 }} 
                animate={{ x: 0, opacity: 1 }}
                transition={{ duration: 0.5, delay: index * 0.05 }} 
            >
                {char}
            </motion.span>
        ));
    };

    useEffect(() => {
        window.scrollTo(0, 0);
    }, [])

    return (
        <body>
            <section>
                <div className='divOne'>
                    <div className='contentText'>
                        <h1>
                            {splitText('Travo 500')}
                        </h1>
                        <p>
                            {splitText('  Pulling More Than Just Weight we Pull Productivity')}
                        </p>
                    </div>
                </div>
            </section>
            <section className='travofeaturesection'>
                <div className='divTwo'>
                    <div className='motionCards'
                    >
                        {/* Card 1 */}
                        <motion.div
                            className='featureCard'
                            initial={{ x: -100, opacity: 0 }}
                            whileInView={{ x: 0, opacity: 1 }}
                            viewport={{ once: true, amount: 0.5 }} 
                            transition={{ duration: 0.4 }}
                        >
                            <img
                                src="/Images/svg/Asset 1.svg"
                                alt="Icon"

                            />
                            <h3>8 Hrs</h3>
                            <p>
                                Capable of running for 8 hours straight, it effortlessly tows loads of up to 500 kg, ensuring reliability and efficiency for heavy tasks.
                            </p>
                        </motion.div>

                        {/* Card 2 */}
                        <motion.div
      
                            className='featureCard'
                            initial={{ x: -100, opacity: 0 }}
                            whileInView={{ x: 0, opacity: 1 }}
                            viewport={{ once: true, amount: 0.5 }}
                            transition={{ duration: 0.9, delay: 0.2 }}
                        >
                            <img
                                src="/Images/svg/Asset 2.svg"
    
                                alt='Icon'
                            />
                            <h3>Max Speed</h3>
                            <p>
                                Reaches a maximum speed of 1.5 m/s, providing quick and reliable movement for optimal task efficiency.
                            </p>
                        </motion.div>

                        {/* Card 3 */}
                        <motion.div
        
                            className='featureCard'
                            initial={{ x: -100, opacity: 0 }}
                            whileInView={{ x: 0, opacity: 1 }}
                            viewport={{ once: true, amount: 0.5 }}
                            transition={{ duration: 0.9, delay: 0.4 }}
                        >
                            <img
                                src="/Images/svg/Asset 3.svg"
                                alt='Icon'
                            />
                            <h3>Payload</h3>
                            <p>
                                Capable of towing loads up to 500 kg, designed for heavy-duty tasks with reliable strength and performance.
                            </p>
                        </motion.div>

                        {/* Card 4 */}
                        <motion.div
                            className='featureCard'
                            initial={{ x: -100, opacity: 0 }}
                            whileInView={{ x: 0, opacity: 1 }}
                            viewport={{ once: true, amount: 0.5 }}
                            transition={{ duration: 1.1, delay: 0.5 }}

                        >
                            <img
                                src="/Images/svg/Asset 4.svg"
                                alt='Icon'
                            />
                            <h3>Autonomous</h3>
                            <p>
                                Automatically recharges itself, ensuring uninterrupted operation with no manual intervention required.
                            </p>
                        </motion.div>

                        {/* Card 5 */}
                        <motion.div
                            className='featureCard'
                            initial={{ x: -100, opacity: 0 }}
                            whileInView={{ x: 0, opacity: 1 }}
                            viewport={{ once: true, amount: 0.5 }}
                            transition={{ duration: 1.3, delay: 0.6 }}
                        >
                            <img
                                src="/Images/svg/Asset 5.svg"

                                alt='Icon'
                            />
                            <h3>Tripple</h3>
                            <p>
                                Triple-layered protection system ensuring optimal safety and maximum reliability for every operation.
                            </p>
                        </motion.div>

                        {/* Card 6 */}
                        <motion.div
                            className='featureCard'
                            initial={{ x: -100, opacity: 0 }}
                            whileInView={{ x: 0, opacity: 1 }}
                            viewport={{ once: true, amount: 0.5 }}
                            transition={{ duration: 1.5, delay: 0.9 }}
                        >
                            <img
                                src="/Images/svg/Asset 14.svg"
                                alt='Icon'
                            />
                            <h3>Navigation</h3>
                            <p>
                                Operates with SLAM (Simultaneous Localization and Mapping) for accurate navigation without reliance on external markers.
                            </p>
                        </motion.div>
                    </div>


                </div>
            </section>

            <div className='divThree'>
                <div className="row">
                   
                    <div className="col-sm-12 col-md-6 col-lg-6 d-flex flex-column justify-content-center align-items-center">
                        <img
                            src="/Images/amr/travo-new.jpg"
                            alt="Product"
                            className='travoimg'
                        />
                    </div>

                
                    <div className="col-lg-6 col-md-6 col-sm-12 d-flex flex-column justify-content-center mt-md-5 mb-lg-3" style={{ backgroundColor: '#f1f1f4' }}> 
                        <div className="courses-container mb-lg-3 mb-md-3">
                            <div className="course">
                                <div className="course-preview">
                                    <img
                                        src="/Images/modular.png"
                                        alt='ModularIcon'
                                    />
                                </div>
                                <div className="course-info text-center">
                                    <h4>Modular Design</h4>
                                    <p>Allows easy upgrades or changes to meet specific operational needs</p>
                                </div>
                            </div>
                        </div>

                       
                        <div className="courses-container mb-lg-3 mb-md-3">
                            <div className="course">
                                <div className="course-preview">
                                    <img
                                        src="/Images/flexibility.png"
                                        alt='flexibilityIcon'
                                    />
                                </div>
                                <div className="course-info text-center">
                                    <h4>Enhanced Payload Flexibility</h4>
                                    <p>Supports multiple payload options and can be easily customized to carry different types of cargo.</p>
                                </div>
                            </div>
                        </div>

                        
                        <div className="courses-container mb-lg-3 mb-md-3">
                            <div className="course">
                                <div className="course-preview">
                                    <img
                                        src="/Images/interlock.png"
                                        alt='intrelockIcon'
                                    />
                                </div>
                                <div className="course-info text-center">
                                    <h4>On-Board Safety Interlocks</h4>
                                    <p >Built-in safety features ensure secure operation in dynamic environments.</p>
                                </div>
                            </div>
                        </div>



                        <div className='downloadbutton'>
                            <div id="button-container">
                                <div
                                    className="button v19 white labelgray"
                                    onClick={() => {
                                        const link = document.createElement('a');
                                        link.href = '/AMR Brochure for website.pdf'; 
                                        link.download = 'Travo-500 Brochure';
                                        link.click();
                                    }}
                                >
                                    <span className="label">Download Brochure</span>
                                    <span className="icon">
                                        <span></span>
                                    </span>
                                    <span className="icon2"></span>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </body>

    )
};

export default Pro;