import React, { useState, useEffect, useRef } from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';
import Swal from 'sweetalert2';
import { useNavigate } from 'react-router-dom';
import 'animate.css';
import AOS from 'aos';
import 'aos/dist/aos.css';
import './Landingpage.css';
import { motion } from 'framer-motion';


const Landingpage = () => {

    const videoRef = useRef(null);
    const tagLineRef = useRef(null);
    const tagDesLineRef = useRef(null);
    const productImageRef = useRef(null);
    const productSectionRef = useRef(null);

    const navigate = useNavigate();

    const productsPage = () => {
        navigate('/travo'); // Navigate to the My Account page
    };

    const softwarePage = () => {
        navigate('/fleetsoftware'); // Navigate to the My Account page
    };



    const [emailData, setEmailData] = useState({
        from: '',
        subject: 'Reg: AMR',
        organization: '',
        name: '',
        message: ''
    });

    const handleChange = (e) => {
        const { name, value } = e.target;
        setEmailData({
            ...emailData,
            [name]: value
        });
    };


    const handleSubmit = (e) => {
        e.preventDefault();

        const text = "Name: " + emailData.name + "\nEmail: " + emailData.from + "\nOrganization: " + emailData.organization + "\nMessage: " + emailData.message;


        if (!window.Email) {
            alert("Email Server Not found!!!");
            return;
        }

        window.Email.send({
            Host: "smtp.elasticemail.com",
            Username: "nexstriderobotics@gmail.com",
            Password: "C95EE0456C257E7728D5F6F7D233BC8F76D8",
            To: 'nexstriderobotics@gmail.com',
            From: 'nexstriderobotics@gmail.com',
            Subject: "Reg: AMR",
            Body: text,

        }).then(
            message => {
                if (message === 'OK') {
                    Swal.fire({
                        toast: true,          // Enable toast mode
                        position: 'top-end',  // Position of the toast (you can adjust this)
                        icon: 'success',      // Set an icon (optional)
                        title: 'Mail Sent Successfully!!!',  // The message
                        showConfirmButton: false,  // Remove the confirm button
                        timer: 3000,          // Toast duration in ms
                        timerProgressBar: true, // Show a progress bar for the timer
                    });
                    setEmailData({
                        from: '',
                        name: '',
                        subject: 'Reg: AMR',
                        organization: '',
                        message: '',
                    })
                }
                else {
                    Swal.fire({
                        toast: true,          // Enable toast mode
                        position: "top-end",  // Position of the toast (you can adjust this)
                        icon: 'warning',      // Set an icon (optional)
                        html: 'Server busy!!!<br></br>Please contact <b>sales@nexstriderobotics.com<b>',  // The message
                        showConfirmButton: false,  // Remove the confirm button
                        timer: 5000,          // Toast duration in ms
                        timerProgressBar: true, // Show a progress bar for the timer,
                        width:window.innerWidth > 767 ?'35%' : '70%'
                    });
                    setEmailData({
                        from: '',
                        name: '',
                        subject: 'Reg: AMR',
                        organization: '',
                        message: '',
                    })
                }
            }

        );

    };


    const splitText = (text) => {
        return text.split('').map((char, index) => (
            <motion.span
                key={index}
                initial={{ x: -20, opacity: 0 }} // Start position and opacity
                animate={{ x: 0, opacity: 1 }} // End position and opacity
                transition={{ duration: 0.5, delay: index * 0.05 }} // Animation delay for each character
            >
                {char}
            </motion.span>
        ));
    };





    return (
        <div data-bs-spy="scroll" data-bs-target=".navbar" data-bs-offset="50" style={{ width: window.innerWidth < 868 ? '100%' : '100%', overflowX: 'hidden', overflowY: 'hidden' }} >


            {/*------------------------------------------Home Section--------------------------------------------------------------------------- */}

            <section id="home" className='homeSection'>
                <div className='videoBackground'>
                    <video
                        autoPlay
                        loop
                        playsInline
                        muted
                        className="background-video"
                    >
                        <source src="/videos/WebsiteBackground.mp4" type="video/mp4" />
                        Your browser does not support the video tag.
                    </video>

                    <div className='overlay-content'>
                        <div className='tag-line'>
                            <p>{splitText('NexStride Robotics')}</p>
                        </div>
                        <div className='tag-description'>
                            <p>{splitText('Sensible Mobility')}</p>
                        </div>
                    </div>
                </div>
            </section>
            {/*----------------------------------------------------Home Section End---------------------------------------------------------------------------------------------------*/}

            {/*----------------------------------------------------------Products Section---------------------------------------------------------------------------------------------*/}

            <section id="products" className="productsSection">
                {/*------------------------------------------------------Travo 500----------------------------------------------------------------------------------------------------*/}
                <div className='travoDiv' >
                    <div className='imagebox' ></div>

                    <div className="contentbox">
                        <h2><b>TRAVO 500</b></h2>
                        <p>Travo designed to tackle labor shortages by efficiently transporting trolleys in manufacturing and assembly logistics. With its reliable performance and advanced navigation, Travo streamlines operations and enhances productivity in industrial environments.</p>

                        <div className='listbox'>
                            <div className='listItem01'>01</div>
                            <div className='listItemContent'>
                                <h5>Payload 500kg</h5>
                            </div>
                        </div>

                        <div className='listbox box2'>
                            <div className='listItem02'>02</div>
                            <div className='listItemContent'>
                                <h5>Max Speed 1.5 m/s <br></br></h5>
                            </div>
                        </div>

                        <div className='listbox box3'>
                            <div className='listItem03'>03</div>
                            <div className='listItemContent'>
                                <h5>Autonomous Docking </h5>
                            </div>
                        </div>

                        {/* <div id="knowmorecontainer">
                            <button className="learn-more">
                                <span className="circle" aria-hidden="true">
                                    <span className="icon arrow"></span>
                                </span>
                                <span className="button-text" onClick={productsPage}>Know More</span>
                            </button>
                        </div> */}
                        <div id="knowmorecontainer">
                            <button className="learn-more" onClick={productsPage}>
                                <span className="circle" aria-hidden="true">
                                    <span className="icon arrow"></span>
                                </span>
                                <span className="button-text">Know More</span>
                            </button>
                        </div>

                    </div>

                </div>
                {/*---------------------------------------------------------Travo 500 end-----------------------------------------------------------------------------------------*/}

                {/*---------------------------------------------------------Fleet Software----------------------------------------------------------------------------------------*/}

                <div className='fleetSoftwareDiv'>
                    <div className='fleetImagebox'></div>

                    <div className='fleetcontentbox'>
                        <h2><b>NXS Fleet Manager</b></h2>
                        <p>Fleet Software revolutionizes autonomous robot management with intelligent task scheduling, real-time monitoring, and safety features like geo-fencing and collision prevention. Its intuitive interface and advanced analytics maximize productivity and efficiency with ease.</p>

                        <div style={{ justifyItems: 'center' }}>
                            <div className='listbox1 fleetbox1'>
                                <div className='fleetListItem1'>01</div>
                                <div className='fleetlistItemContent'>
                                    <h5>IOT- Industry 4.0 ready</h5>
                                </div>
                            </div>



                            <div className='listbox1'>
                                <div className='fleetListItem1'>02</div>
                                <div className='fleetlistItemContent'>
                                    <h5>Intelligent Mission Scheduling</h5>
                                </div>
                            </div>
                            <div className='listbox1 fleetbox3'>
                                <div className='fleetListItem1'>03

                                </div>
                                <div className='fleetlistItemContent'>
                                    <h5>Insight-Driven Analytics</h5>
                                </div>
                            </div>

                            <div id="knowmorecontainer" >
                                <button className="learn-more">
                                    <span className="circle" aria-hidden="true">
                                        <span className="icon arrow"></span>
                                    </span>
                                    <span className="button-text" onClick={softwarePage}>Know More</span>
                                </button>
                            </div>
                        </div>

                    </div>
                </div>
                {/*----------------------------------------------------------------Fleet Software ends------------------------------------------------------------------------------*/}
            </section>

            {/*--------------------------------------------------------------------Product Section End ---------------------------------------------------------------------------- */}

            {/*--------------------------------------------------------------------About us-----------------------------------------------------------------------------------------*/}

            <section id="about" className='about-section' >

                <div className='triangleabout' >
                    <div className='textDiv'>
                        <h2><b>About Us</b></h2>
                        <p>
                            We are a tech-driven startup transforming manufacturing with cutting-edge Autonomous Mobile Robots (AMRs).</p>
                        <p> Our intelligent, adaptable robots seamlessly integrate into workflows, enhancing efficiency, safety, and scalability. </p>
                        <p>Equipped with advanced navigation, vision, and sensors, they navigate dynamic environments and handle complex tasks effortlessly. </p>
                        <p>Partner with us to revolutionize manufacturing with smart, autonomous robotics!</p>
                    </div>
                </div>


                <div className="aboutrow">
                    <div className="aboutcolumn">
                        <div className='aboutcard'>
                            <div className='aboutCardIcon'>
                                <img src="/Images/svg/workflow.svg" alt="Optimize Workflow" loading='lazy' style={{}} className="about-icon" />
                            </div>
                            <div className='aboutCardContent' >
                                <h3><b>Optimize Workflow</b></h3>
                                <p>Our robots automate material handling, reduce downtime, and enhance production speed, enabling teams to focus on high-value tasks</p>
                            </div>
                        </div>
                    </div>

                    <div className="aboutcolumn">
                        <div className='aboutcard'>
                            <div className='aboutCardIcon'>
                                <img src="/Images/svg/safety.svg" alt="Enhance Safety" loading='lazy' className="about-icon" />
                            </div>
                            <div className='aboutCardContent'>
                                <h3><b>Enhance Safety</b></h3>
                                <p>By deploying AMRs in critical processes, manufacturers can minimize human exposure to hazardous conditions, reducing workplace incidents</p>
                            </div>
                        </div>
                    </div>

                    <div className="aboutcolumn">
                        <div className='aboutcard'>
                            <div className='aboutCardIcon'>
                                <img src="/Images/svg/efficiency.svg" alt="Scale Efficiently" loading='lazy' className="about-icon" />
                            </div>
                            <div className='aboutCardContent'>
                                <h3><b>Scale Efficiently</b></h3>
                                <p>Our scalable, modular systems allow companies to expand their automation capabilities without overhauling existing infrastructure</p>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            {/*-----------------------------------------------------------------About us section ends-----------------------------------------------------------------------------*/}

            {/*-----------------------------------------------------------------Connect with us-----------------------------------------------------------------------------------*/}
            <section id='contact'>
                <div className='row'>
                    <div className='d-lg-flex row align-items-lg-center'>
                        <div className='col-lg-5 col-md-5'>
                            <form className="row g-3 contactForm" onSubmit={handleSubmit}>
                                <h1 className="mb-4 ms-4 mb-md-3"><b>Connect With Us</b></h1>
                                <div className='row'>
                                    <div className="col-md-6 contactFormBox">
                                        <input type="text" className="form-control mb-2 mb-md-4 p-3" id="name" name="name" value={emailData.name} onChange={handleChange} placeholder="Enter your name" required />
                                    </div>
                                    <div className="col-md-6 contactFormBox">
                                        <input type="email" className="form-control mb-2 mb-md-4 p-3" id="email" placeholder="Your email" name='from' value={emailData.from} onChange={handleChange} required />
                                    </div>
                                </div>
                                <div className='row'>
                                    <div className="col-md-12 contactFormBox">
                                        <input type="text" className="form-control mb-2 mb-md-4 p-3" id="subject" name='organization' value={emailData.organization} onChange={handleChange} placeholder="Organization" required />
                                    </div>
                                </div>
                                <div className='row'>
                                    <div className="col-md-12 contactFormBox">
                                        <textarea
                                            className="form-control mb-2 mb-md-4 p-3"
                                            id="message"
                                            placeholder="Your message"
                                            name='message' value={emailData.message} onChange={handleChange}
                                            required
                                        ></textarea>
                                    </div>
                                </div>
                                <div className='row'>
                                    <div id="knowmorecontainer">
                                        <button className="learn-more" type='submit'>
                                            <span className="circle" aria-hidden="true">
                                                <span className="icon arrow"></span>
                                            </span>
                                            <span className="button-text" style={{ marginLeft: '-5%' }}>Submit</span>
                                        </button>
                                        {/* </div> */}
                                    </div>
                                </div>
                            </form>
                        </div>

                        <div className='col-lg-8 col-md-7 rightsideconnectpage'></div>
                    </div>
                </div>
            </section>
        </div>
    );
};

export default Landingpage;




// <section id='contact' className='connectWithUsSection'>
// <div className='row'>
//     <div className='columnForm'>
//         <form className="formContainer" onSubmit={handleSubmit}>
//             <h1 className="connectHeading">Connect With Us</h1>
//             <div className='row'>
//                 <div className="col-md-6">
//                     <input type="text" className="inputBox" id="name" name="name" value={emailData.name} onChange={handleChange} placeholder="Enter your name" required />
//                 </div>
//                 <div className="col-md-6">
//                     <input type="email" className="inputBox" id="email" placeholder="Your email" name='from' value={emailData.from} onChange={handleChange} required />
//                 </div>
//             </div>
//             <div className='row'>
//                 <div className="col-md-12">
//                     <input type="text" className="inputBox" id="subject" name='organization' value={emailData.organization} onChange={handleChange} placeholder="Organization" required />
//                 </div>
//             </div>
//             <div className='row'>
//                 <div className="col-md-12">
//                     <textarea
//                         className="textAreaBox"
//                         id="message"
//                         placeholder="Your message"
//                         name='message' value={emailData.message} onChange={handleChange}
//                         required
//                     ></textarea>
//                 </div>
//             </div>

//             <div className='row'>

//                 <div id="knowmorecontainer">
//                     <button className="learn-more" type='submit'>
//                         <span className="circle" aria-hidden="true">
//                             <span className="icon arrow"></span>
//                         </span>
//                         <span className="button-text" style={{ marginLeft: '-5%' }}>Submit</span>
//                     </button>
//                 </div>
//             </div>
//         </form>
//     </div>

//     <div className='col-lg-7 col-md-7 rightsideconnectpage'></div>

// </div>
// </section>