import React, { useState, useEffect } from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';
import 'bootstrap/dist/js/bootstrap.bundle.min.js';
import { HashLink as Link } from 'react-router-hash-link';
import './Navbar.css'
import { CgMenuMotion } from "react-icons/cg";
import { useNavigate } from 'react-router-dom';
import { Offcanvas } from 'react-bootstrap';


const Navbar = () => {
  // const [showNavbar, setShowNavbar] = useState(true);
  const [lastScrollTop, setLastScrollTop] = useState(0);
  const [show, setShow] = useState(false);
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  useEffect(() => {
    const handleScroll = () => {
      const currentScrollTop = window.pageYOffset || document.documentElement.scrollTop;
      setLastScrollTop(currentScrollTop <= 0 ? 0 : currentScrollTop); // Prevent negative scroll
    };

    window.addEventListener('scroll', handleScroll);
    return () => {
      window.removeEventListener('scroll', handleScroll);
    };

  }, [lastScrollTop]);

  const navigate = useNavigate();

  const productsPage = () => {
    navigate('/travo'); // Navigate to the My Account page
  };

  const softwarePage = () => {
    navigate('/fleetsoftware'); // Navigate to the My Account page
  };


  return (
    <div data-bs-spy="scroll" data-bs-target=".navbar" data-bs-offset="50">
      {/* Navbar */}
      <nav
        className="navbar navbar-expand-sm navbar-dark fixed-top navbarContainer"
      >
        <div className="container-fluid">

          <Link to="/" className="navbar-brand">
            <img
              src="/Images/logo/Logo_White.svg"
              alt="Logo"
              className="navbar-logo"
            />
          </Link>


          <button
            className="navbar-toggler ms-auto d-sm-none"
            type="button"
            onClick={handleShow} // Use the state to toggle offcanvas visibility
            style={{
              border: 'none',
              outline: 'none',
              boxShadow: 'none',
            }}
          >

            <CgMenuMotion style={{ width: '30px', height: '30px', padding: '-10px' }} />

          </button>

          <div className="collapse navbar-collapse d-none d-sm-flex">
            <ul className="navbar-nav ms-auto d-flex align-items-center" style={{ gap: '2rem' }}>
              <li className="nav-item">
                <Link className="nav-link" to="/#home" smooth>
                  <p className="m-0">Home</p>
                </Link>
              </li>



              <li className="nav-item dropdown">
                <div
                  className="nav-link dropdown-toggle"
                  role="button"
                  style={{ display: 'flex', alignItems: 'center', cursor: 'pointer' }}
                >
                  <p className="m-0" style={{ marginRight: 'auto' }}>Products</p>
                  <span className="ms-3 mt-1"></span>
                </div>
                <ul className="dropdown-menu">
                  <li>
                    <Link className="dropdown-item" to="/products/travo500" onClick={(e) => {
                      e.preventDefault();
                      productsPage(); // Call the productsPage function to navigate
                    }}>
                      Travo 500
                    </Link>
                  </li>
                  <li>
                    <Link className="dropdown-item" href="#"
                      onClick={(e) => {
                        e.preventDefault();
                        softwarePage();
                      }}>
                      Fleet manager
                    </Link>
                  </li>
                </ul>
              </li>





              <li className="nav-item">
                <Link className="nav-link" to="/#about" style={{ cursor: 'pointer' }} smooth>
                  <p className="m-0">About Us</p>
                </Link>
              </li>
              <li className="nav-item">
                <Link className="nav-link" to="/#contact" smooth>
                  <p className="m-0">Connect With Us</p>
                </Link>
              </li>
            </ul>
          </div>



          {/* Offcanvas Component */}
          <Offcanvas show={show} onHide={handleClose} placement="end" id="offcanvasNavbar" style={{ backgroundColor: '#0083b3', height: '50%', width: '50%' }}>
            <Offcanvas.Header closeButton>

            </Offcanvas.Header>
            <Offcanvas.Body>
              {/* Anchor Links inside Offcanvas */}

              <div className="offcanvas-body">
                <ul className="navbar-nav ms-auto" >
                  <li className="nav-item" >
                    <Link className="nav-link" to="/#home" onClick={handleClose} smooth>
                      Home
                      <hr></hr>
                    </Link>
                  </li>

                  <li className="nav-item">
                    <Link className="nav-link" to="/#products" onClick={handleClose} smooth
                    >
                      Products
                      <hr></hr>
                    </Link>
                  </li>

                  <li className="nav-item">
                    <Link className="nav-link" to="/#about" onClick={handleClose} smooth>
                      About Us
                      <hr></hr>
                    </Link>
                  </li>
                  <li className="nav-item">
                    <Link className="nav-link" to="/#contact" onClick={handleClose} smooth>
                      Connect With Us
                    </Link>
                  </li>
                </ul>
              </div>
            </Offcanvas.Body>
          </Offcanvas>
        </div>
      </nav>
    </div>

  );
};

export default Navbar;