
import './App.css';
import { BrowserRouter as Router, Routes, Route} from 'react-router-dom';
import Landingpage from './Screens/LandingPage/Landingpage';
import React from 'react';
import Navbar from './Components/NavBar/Navbar';
import Footer from './Components/Footer/Footer';
import Fleetsoftware from './Screens/Fleetsoftware/Fleetsoftware';
import Pro from './Screens/Travo500/Travo';
import Sample from './Screens/sample/sample';


function App() {
  return (
    <div className="App">
      <Router>
        <Navbar />
        <Routes>
          <Route path='/' element={<Landingpage />} />
          <Route path='/fleetsoftware' element={<Fleetsoftware />} />
          <Route path='/travo' element={<Pro />} />
          <Route path='/try' element={<Sample/>} />
        </Routes>
        <Footer />
      </Router>
    </div>
  );
}

export default App;
