import React from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';

const Footer = () => {
  return (
    <div data-bs-spy="scroll" data-bs-target=".navbar" data-bs-offset="50">
      <footer className="container-fluid bg-4 text-center" style={{ height: 'auto', backgroundColor: 'black', padding: window.innerWidth < 867 ? '50px 100px ' : '50px 200px' , zIndex:'1000',width:'auto'}}>
        <div className="row">
          {/* Address */}
          <div className="col-12 col-md-4" style={{textAlign:'left', color:'white'}}>
            <h5>Contact Us</h5>
            <br></br>
            NexStride Robotics,
            <p>Plot 164, 5th A Cross,<br></br>
            Bommasandra Industrial Area,<br></br>
            Bengaluru, Karnataka 560099</p>
            <p>Email: sales@nexstriderobotics.com</p>
          </div>

          {/* Social Media Links */}
          <div className="col-12 col-md-4" style={{color:'white'}}>
            <h5>Follow Us</h5>
            {/* <a href="https://facebook.com" target="_blank" rel="noopener noreferrer" style={{ marginRight: '15px' }}>
              <i className="fab fa-facebook" style={{ fontSize: '24px', color: '#3b5998' }}></i>
            </a> */}
            <a href="https://www.linkedin.com/company/nexstride-robotics/" target="_blank" rel="noopener noreferrer" style={{ marginRight: '15px' }}>
              <i className="fab fa-linkedin" style={{ fontSize: '24px', color: '#0077b5' }}></i>
            </a>
            {/* <a href="https://instagram.com" target="_blank" rel="noopener noreferrer" style={{ marginRight: '15px' }}>
              <i className="fab fa-instagram" style={{ fontSize: '24px', color: '#e4405f' }}></i>
            </a> */}
          </div>

          {/* Navigation Links */}
          {/* <div className="col-12 col-md-4" style={{color:'white'}}>
            <h5>Quick Links</h5>
            <ul className="list-unstyled">
              <li>
                <a href="#privacy-policy" style={{ color: '#fff' }}>Privacy Policy</a>
              </li>
              <li>
                <a href="#teams" style={{ color: '#fff' }}>Our Teams</a>
              </li>
              <li>
                <a href="#terms-conditions" style={{ color: '#fff' }}>Terms and Conditions</a>
              </li>
            </ul>
          </div> */}
        </div>

        <hr style={{ borderTop: '1px solid #ddd', margin: '20px 0' }} />

        <p>&copy; {new Date().getFullYear()} Procyon TechSolutions Pvt Ltd. All Rights Reserved.</p>
      </footer>
    </div>
  );
};

export default Footer;
